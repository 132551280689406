import Vue from "vue";
import { IVisibleWords } from "@/store/analyzes/analyzes-types";
import { checkPunctuation } from "@/utils/helper";

export default Vue.extend({
  name: "AnalysisHighlightedWord",
  props: ["btnClassNames", "word", "begin", "visibleWord"],
  mounted() {
    this.$store.commit("analyzes/SET_ANALYSIS_WORDS_REFS", this.$refs);
  },
  methods: {
    hasPunctuation(visibleWords: IVisibleWords) {
      return checkPunctuation(visibleWords?.visibleWordItem);
    },
  },
});
