import Vue from "vue";
import Timeline from "@/components/Analysis/Player/Timeline/index.vue";
import { Analysis, ICroppedDuration } from "@/store/analyzes/analyzes-types";
import { timeFormat } from "@/utils/helper";

export default Vue.extend({
  name: "Player",
  props: ["fileName", "result"],
  components: { Timeline },
  data() {
    return {
      currentTime: 0,
      duration: 0,
      timelineExpanded: false,
      mousedown: false,
      volume: 100,
      isAudioLoaded: false,
      link: "",
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    isTimelineDisabled(): boolean {
      return ["failed", "aborted"].includes(this.$storeAnalysis.status);
    },
    isTimelineLoading(): boolean {
      return (
        !this.isTimelineDisabled &&
        !["completed"].includes(this.$storeAnalysis.status) &&
        (!this.result || !this.duration)
      );
    },
    audioDuration(): number {
      return this.croppedDuration || this.duration;
    },
    croppedDuration(): number {
      const croppedDurations: ICroppedDuration[] =
        this.$store.state.analyzes.croppedDurations;
      const croppedDuration = croppedDurations.find(
        (item) => item.id === this.$storeAnalysis.file_id
      )?.croppedDuration;

      return croppedDuration || 0;
    },
    isAudioAvailable(): boolean {
      return (!!this.croppedDuration && !this.result) || !this.link;
    },
  },
  methods: {
    play() {
      if ((<HTMLAudioElement>this.$refs.audio)?.paused) {
        (<HTMLAudioElement>this.$refs.audio).play();
      } else (<HTMLAudioElement>this.$refs.audio).pause();
    },
    changeAudioProgress(event: number) {
      const audio = <HTMLAudioElement>this.$refs.audio;
      audio.currentTime = event;
    },
    time() {
      if (!this.mousedown) {
        this.currentTime = (<HTMLAudioElement>this.$refs.audio)?.currentTime;
        this.$emit("ct", this.currentTime);
      }
    },
    canPlay() {
      this.duration = (<HTMLAudioElement>this.$refs.audio)?.duration;
    },
    playSelected(startTime: number, play?: boolean) {
      (<HTMLAudioElement>this.$refs.audio).currentTime = startTime;
      if (play) (<HTMLAudioElement>this.$refs.audio).play();
    },
    formatTime(time: number): string {
      return timeFormat(time, 1);
    },
    mute() {
      (<HTMLAudioElement>this.$refs.audio).muted = !(<HTMLAudioElement>(
        this.$refs.audio
      )).muted;
      this.$forceUpdate();
    },
    changeValue(event: number) {
      (<HTMLAudioElement>this.$refs.audio).volume = event / 100;
      (<HTMLAudioElement>this.$refs.audio).muted = false;
      this.$forceUpdate();
    },
  },

  watch: {
    "$storeAnalysis.result": {
      handler() {
        const fileLink = this.$storeAnalysis.file_link;

        if (fileLink) {
          this.link = fileLink;
        }
      },
    },
    "$storeAnalysis.file_link": {
      handler(newValue) {
        if (!this.croppedDuration && newValue) {
          this.link = newValue;
        }
      },
    },
  },
  mounted() {
    const audio = this.$refs.audio as HTMLAudioElement;
    const fileLink = this.$storeAnalysis.file_link;

    if (fileLink && this.$storeAnalysis.result) {
      this.link = fileLink;
    }

    audio.load();
  },
});
