import Vue from "vue";

export default Vue.extend({
  name: "PaymentCanceled",
  data() {
    return {
      message: "",
    };
  },
  created() {
    this.message = this.$route.params.message;
  },
  computed: {
    link(): { name: string; hash: string } {
      return this.message
        ? { name: "Tariffication", hash: "#storageScrollAnchor" }
        : { name: "Tariffication", hash: "#plansScrollAnchor" };
    },
  },
});
