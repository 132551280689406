import Vue from "vue";
import PaymentCanceled from "./PaymentCanceled/index.vue";
import PaymentSuccess from "./PaymentSuccess/index.vue";

export default Vue.extend({
  name: "PaymentResult",
  components: {
    PaymentCanceled,
    PaymentSuccess,
  },
});
