import VueRouter from "vue-router";

export const SHOW_ZOMBTY_LOGIN = Boolean(process.env.VUE_APP_ZOMBTY_FRONT_URL);

export const GET_ZOMBTY_AUTH_URL = (router: VueRouter): string => {
  const zombtyUrl =
    process.env.VUE_APP_ZOMBTY_FRONT_URL ?? "https://zombty.com";
  const path =
    router.match({ name: "ZombtyLoginCallback" }).path ?? "/zombty-callback";
  return `${zombtyUrl}/auth?redirect_uri=${location.origin}${path}`;
};
