export enum SuccessMessages {
  "An error occurred on the server. Please, try again" = -1,
  "Registration is completed. Check your email and confirm your account to access all the features of Senseprofile." = 1,
  "Password was successfully reset. Log in to continue" = 2,
  "Mail change canceled" = 3,
  "We have sent a new confirmation link to your email. Follow the instructions from the email to complete the email confirmation." = 4,
  "Password changed successfully" = 5,
  "We have sent a new confirmation link to your new email. Follow the instructions from the email to complete the email confirmation." = 6,
  "Thank you for the feedback" = 7,
}
