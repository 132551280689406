import Vue from "vue";

export default Vue.extend({
  name: "DownloadingApp",
  data() {
    return {
      zoomLink: process.env.VUE_APP_ZOOM_MARKETPLACE_LINK,
    };
  },
  computed: {
    isMeetingLoading(): boolean {
      return this.$store.state.zoom.isMeetingLoading;
    },
  },
});
