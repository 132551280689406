import { Module } from "vuex";
import axios from "axios";
import { removeZoomCode } from "@/utils/helper";
import { RootState } from "@/store/store-types";
import { ZoomState } from "@/store/zoom/zoom-types";
import { setErrorMessage } from "@/utils/errors";

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;
const initialState: ZoomState = {
  meetings: [],
  isConfirmLoading: false,
  isMeetingLoading: false,
  isRecordUploading: false,
};
export const zoom: Module<ZoomState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {},
  mutations: {
    SET_ZOOM_MEETINGS(state: ZoomState, meetings: []) {
      state.meetings = meetings;
    },
    SET_CONFIRM_LOADING(state: ZoomState, payload: boolean) {
      state.isConfirmLoading = payload;
    },
    SET_MEETINGS_LOADING(state: ZoomState, payload: boolean) {
      state.isMeetingLoading = payload;
    },
    SET_RECORD_UPLOAD_LOADING(state: ZoomState, payload: boolean) {
      state.isRecordUploading = payload;
    },
  },
  actions: {
    CONFIRM_ZOOM_ACCOUNT({ commit }, code: string) {
      commit("SET_CONFIRM_LOADING", true);
      return axios
        .post(`${api}/api/zoom/signIn`, { code })
        .then((res) => {
          commit("SET_CONFIRM_LOADING", false);
          if (res?.data?.response) {
            removeZoomCode();
            return res?.data?.response;
          } else throw new Error("error");
        })
        .catch((err) => {
          commit("SET_CONFIRM_LOADING", false);
          if (err?.response?.data?.error) {
            throw new Error(err.response.data.error.message);
          } else {
            throw new Error(err);
          }
        });
    },
    GET_ZOOM_MEETINGS({ commit }) {
      commit("SET_MEETINGS_LOADING", true);
      axios
        .get(`${api}/api/zoom/records`)
        .then((res) => {
          if (res?.data?.response?.records) {
            commit("SET_MEETINGS_LOADING", false);
            commit("SET_ZOOM_MEETINGS", res.data.response.records);
          }
        })
        .catch((err) => {
          commit("SET_MEETINGS_LOADING", false);
        });
    },
    UPLOAD_ZOOM_RECORD({ commit }, record_id) {
      commit("SET_RECORD_UPLOAD_LOADING", true);
      return axios
        .post(`${api}/api/zoom/uploadZoomAnalyze`, { record_id })
        .then((res) => {
          commit("SET_RECORD_UPLOAD_LOADING", false);
          if (res?.data?.response?.file_id) return res.data.response;
        })
        .catch((err) => {
          commit("SET_RECORD_UPLOAD_LOADING", false);
          if (err?.response?.data?.error?.code) {
            const errCode: number = err?.response?.data?.error?.code;
            throw new Error(setErrorMessage(errCode));
          } else {
            throw new Error(setErrorMessage(-2));
          }
        });
    },
  },
};
