import Vue from "vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { ITaskStatuses } from "@/types/shared-types";
import LinearProgress from "@/components/shared/LinearProgress/index.vue";

export default Vue.extend({
  name: "AnalysisProgress",
  components: {
    LinearProgress,
  },
  props: ["progressStatus"],
  data() {
    return {};
  },
  computed: {
    $currentStatus(): ITaskStatuses {
      return this.$storeAnalysis.status;
    },
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    showAbortBtn(): boolean {
      return !["transcription", "completed"].includes(this.$currentStatus);
    },
  },
  methods: {
    abortTask() {
      this.$store.dispatch("analyzes/ABORT_TASK", this.$storeAnalysis.id);
    },
  },
});
