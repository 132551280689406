import { Module } from "vuex";
import axios from "axios";
import { IZombtyRecord } from "./zombty-types";
import { RootState } from "../store-types";
import { setErrorMessage } from "@/utils/errors";

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;
const getState = () => ({
  meetings: Array<IZombtyRecord>(),
  isMeetingsLoading: false,
  isRecordUploading: false,
});

export type ZombtyState = ReturnType<typeof getState>;

export const zombty: Module<ZombtyState, RootState> = {
  namespaced: true,
  state: getState,
  mutations: {
    SET_RECORD_UPLOADING(state, payload: boolean) {
      state.isRecordUploading = payload;
    },
    SET_MEETINGS_LOADING(state, payload: boolean) {
      state.isMeetingsLoading = payload;
    },
    SET_MEETINGS_LIST(state, payload?: IZombtyRecord[]) {
      state.meetings = [...(payload ?? [])];
    },
  },
  actions: {
    async GET_MEETINGS_LIST({ commit, dispatch }) {
      try {
        commit("SET_MEETINGS_LOADING", true);
        const {
          data: { response },
        } = await axios.get<{
          response: {
            user_id: number;
            data: IZombtyRecord[];
          };
        }>(`${api}/api/zombty/getZombtyMeetingsList`);
        commit("SET_MEETINGS_LIST", response.data);
      } catch (e) {
        dispatch("API_ERROR_HANDLER", e, { root: true });
      } finally {
        commit("SET_MEETINGS_LOADING", false);
      }
    },
    async UPLOAD_RECORD({ commit }, record_id: string) {
      try {
        commit("SET_RECORD_UPLOADING", true);
        const { data: { response } = {} } = await axios.post(
          `${api}/api/zombty/uploadZombtyAnalyze`,
          { record_id }
        );
        if (response.file_id) {
          return response;
        }
      } catch (err: any) {
        if (err?.response?.data?.error?.code) {
          const errCode: number = err?.response?.data?.error?.code;
          throw new Error(setErrorMessage(errCode));
        } else {
          throw new Error(setErrorMessage(-2));
        }
      } finally {
        commit("SET_RECORD_UPLOADING", false);
      }
    },
  },
  getters: {},
};
