import Vue from "vue";
import { Analysis } from "@/store/analyzes/analyzes-types";

export default Vue.extend({
  name: "AnalysisAbortAlert",
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $APIError(): number | null {
      return this.$store.state.errorAPI;
    },
    $WSError(): number | null {
      return this.$store.state.errorWS;
    },
    isHavePrevResult(): boolean {
      const idx = this.$store.state.analyzes.previousResults.findIndex(
        (t: Analysis) => {
          return t.id === this.$storeAnalysis.id;
        }
      );
      return idx !== -1;
    },
    isConfirmedEmail() {
      return this.$store.state.profile.user.email_confirmed;
    },
  },
  methods: {
    clearWSError(code: number | number[] | null) {
      if (this.$WSError) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    clearAPIError(code: number | number[] | null) {
      if (this.$APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    showPreviousResult() {
      this.clearWSError(null);
      this.clearAPIError(null);
      //fixit
      //this.progressStatus = null;
      this.$store.state.analyzes.currentAnalyze.error_text = "";
      this.$store.state.analyzes.currentAnalyze.status = "completed";
      this.$store.dispatch(
        "analyzes/SHOW_PREVIOUS_RESULT_ACTION",
        this.$storeAnalysis.id
      );
    },
  },
});
