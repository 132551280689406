import { profile } from "./../../../store/profile/index";
import Vue from "vue";
import RenameModal from "@/components/modals/RenameModal/index.vue";
import Player from "@/components/Analysis/Player/index.vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { ITaskStatuses } from "@/types/shared-types";
import { ErrorsMessages } from "@/utils/errors";
import ReAnalyzeModal from "@/components/modals/ReAnalyzeModal/index.vue";

export default Vue.extend({
  name: "AnalysisHeader",
  components: { RenameModal, Player, ReAnalyzeModal },
  props: {
    globalLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      progressStatus: <{ text: string; value: number } | null>null,
      renameDialog: false,
      reAnalyzeDialog: false,
      errorsMessages: ErrorsMessages,
    };
  },
  computed: {
    $currentStatus(): ITaskStatuses {
      return this.$storeAnalysis.status;
    },
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $APIError(): number | null {
      return this.$store.state.errorAPI;
    },
    $WSError(): number | null {
      return this.$store.state.errorWS;
    },
    showAbortBtn(): boolean {
      return !["transcription", "completed"].includes(this.$currentStatus);
    },
    showError(): boolean {
      return (
        this.$storeAnalysis.status === "failed" ||
        (!!this.$APIError && [2181878257, 400].includes(this.$APIError)) ||
        (!!this.$WSError &&
          [2181878257, 1830715152, 400, 3235735763].includes(this.$WSError))
      );
    },
    isHavePrevResult(): boolean {
      const idx = this.$store.state.analyzes.previousResults.findIndex(
        (t: Analysis) => {
          return t.id === this.$storeAnalysis.id;
        }
      );
      return idx !== -1;
    },
    isConfirmedEmail() {
      return this.$store.state.profile.user.email_confirmed;
    },
    isGuestOnline() {
      return this.$store.state.profile.user.is_guest;
    },
  },
  methods: {
    showPreviousResult() {
      this.clearWSError(null);
      this.clearAPIError(null);
      this.progressStatus = null;
      this.$store.state.analyzes.currentAnalyze.error_text = "";
      this.$store.state.analyzes.currentAnalyze.status = "completed";
      this.$store.dispatch(
        "analyzes/SHOW_PREVIOUS_RESULT_ACTION",
        this.$storeAnalysis.id
      );
    },
    duplicateTask() {
      this.$store.dispatch("analyzes/DUPLICATE_TASK", this.$storeAnalysis.id);
    },
    deleteTask() {
      this.$store.dispatch(
        "analyzes/ADD_TASK_TO_DELETE_ACTION",
        this.$storeAnalysis.id
      );
    },
    clearWSError(code: number | number[] | null) {
      if (this.$WSError) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    clearAPIError(code: number | number[] | null) {
      if (this.$APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    downloadPdf() {
      this.$store.dispatch("analyzes/UPDATE_DOWNLOAD_PDF", true);
      this.$store.dispatch("analyzes/GET_PDF_LINK", this.$storeAnalysis.id);
    },
    downloadAudio() {
      this.$store.dispatch(
        "analyzes/DOWNLOAD_PDF",
        this.$storeAnalysis.file_link
      );
    },
  },
});
