import Vue from "vue";
import AnalysisHighlightedWord from "./AnalysisHighlightedWord/index.vue";
import {
  Analysis,
  AnalysisResultWords,
  IVisibleWords,
} from "@/store/analyzes/analyzes-types";
import { msOrSecFormat } from "@/utils/helper";

export default Vue.extend({
  name: "AnalysisResultWord",
  props: {
    word: String,
    visibleWord: Object as () => IVisibleWords,
    begin: Number,
    keyphrase: Array as () => string[],
    keyword: String,
    idx: Number,
    wordIdx: Number,
  },
  components: {
    AnalysisHighlightedWord,
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $isCustomSelection(): boolean {
      return this.$storeAnalysis.isCustomSelection;
    },
    $isShowHighlights(): boolean {
      return this.$storeAnalysis.isShowHighlights;
    },
    getVisibleWord() {
      return this.visibleWord?.visibleWordItem || "";
    },
    getHyphen() {
      return this.visibleWord?.hyphen || "";
    },
    wordsRefs(): Record<string, HTMLElement> {
      return this.$storeAnalysis.wordsRefs;
    },
  },
  methods: {
    trigger(ref: string) {
      this.wordsRefs[ref].click();
    },
    isIncludedInPhrase(keyword: AnalysisResultWords) {
      return this.begin === keyword.begin;
    },
    filterPhrase(keyphrase: string[], word: string) {
      return keyphrase.filter((e) => e.includes(word));
    },
    phraseCount(keyphrase: string[], word: string) {
      return this.filterPhrase(keyphrase, word).reduce(
        (acc, w) =>
          acc + ((this.$storeAnalysis.combinedKeywords || {})[w] || []).length,
        0
      );
    },
    isCustomSelected(keyword: string): boolean {
      return this.$storeAnalysis.keywordsCustomSelection.includes(keyword);
    },
    findPhrase(keyphrase: string[] | undefined, word: string) {
      return keyphrase?.find((e) => e.includes(word));
    },
    findKeyPhraseArr(keyphrase: string[] | undefined, word: string) {
      const key = this.findPhrase(keyphrase, word);
      if (this.$storeAnalysis.combinedKeywords && key)
        return this.$storeAnalysis.combinedKeywords[key] || [];
      return [];
    },
    calculateSpeechStartTime(start: number) {
      return msOrSecFormat(start);
    },
  },
});
