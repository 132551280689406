import Vue from "vue";
import { removePunctuationMark } from "@/utils/helper";
import {
  Analysis,
  ITimeLineDataSpeakers,
} from "@/store/analyzes/analyzes-types";
import AnalysisResultBlock from "./AnalysisResultBlock/index.vue";

export default Vue.extend({
  name: "AnalysisResult",
  components: {
    AnalysisResultBlock,
  },
  props: ["audioTime"],
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $isStatisticsCalculating(): boolean {
      return this.$storeAnalysis.isStatisticsCalculating;
    },
    $visibleSpeakers(): Record<string, ITimeLineDataSpeakers> {
      const timelineDataMain = this.$storeAnalysis.timelineData?.main ?? [];
      return timelineDataMain.reduce(
        (acc, data) => ({ ...acc, [data.speaker]: data }),
        {}
      );
    },
  },
  methods: {
    addKeyword(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (
        target.children.length > 0 ||
        target.localName !== "span" ||
        !this.$storeAnalysis.isAddingKeywords
      ) {
        return;
      }

      const keywordsExpected = this.$storeAnalysis.keywords_expected
        ? `${this.$storeAnalysis.keywords_expected}, `
        : ``;

      const keyword = removePunctuationMark(target.innerText).toLowerCase();

      if (keywordsExpected.includes(keyword)) return;

      this.$storeAnalysis.keywords_expected = `${keywordsExpected}${keyword}`;
    },
    isPlayedBlock(start: number, end: number) {
      return this.audioTime > start && this.audioTime < end;
    },
  },
  beforeDestroy() {
    this.$store.commit("analyzes/SET_ANALYSIS_WORDS_REFS", {});
  },
});
