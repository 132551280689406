import Vue from "vue";

export default Vue.extend({
  computed: {
    availableLanguages() {
      return this.$store.state.availableLanguages;
    },
    languageDetails() {
      return this.$store.getters["GET_LANGUAGES_DETAILS"];
    },
  },
  created() {
    if (!this.availableLanguages.length) {
      this.$store.dispatch("GET_AVAILABLE_LANGUAGES");
    }
  },
});
