import Vue from "vue";

export default Vue.extend({
  name: "PaymentSuccess",

  data() {
    return {
      message: "",
    };
  },

  created() {
    this.message = this.$route.params.message;
  },

  computed: {
    tariff() {
      const tariff = this.$store.state.profile.user.tariff;
      return tariff[0].toUpperCase() + tariff.slice(1);
    },
  },
});
