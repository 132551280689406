var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseleave":function($event){_vm.hoveredItem = null}}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.zombtyRecords,"page":_vm.page,"items-per-page":10,"hide-default-footer":"","loading":_vm.isRecordUploading},on:{"click:row":_vm.setHovered,"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.recordId",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{staticClass:"hover-span",on:{"mouseenter":function($event){return _vm.setHovered(item)}}}),_vm._v(" "+_vm._s(item.fullFileName)+" "),_c('br'),_c('span',[_vm._v("ID "+_vm._s(value))])]}},{key:"item.display_length",fn:function(ref){
var value = ref.value;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(value))])]}},{key:"item.display_size",fn:function(ref){
var value = ref.value;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(value))])]}},{key:"item.recordStartTime",fn:function(ref){
var item = ref.item;
return [(_vm.uploadedRecord.includes(item.recordId))?_c('div',[_vm._v("Uploaded")]):(_vm.hoveredItem !== item.recordId)?_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(item.recordStartTime).toLocaleString())+" ")]):_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[(item.task_id)?_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.openTask(item.task_id)}}},[_vm._v(" Open ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","depressed":"","x-small":"","disabled":_vm.isRecordUploading || !_vm.isUploadFiles,"loading":_vm.isRecordUploading},on:{"click":function($event){return _vm.uploadFile(item)}}},[_vm._v(" Upload ")])],1)]}}])}),_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }