var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(
      (_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length &&
      _vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length &&
      _vm.filterPhrase(_vm.keyphrase, _vm.word).length > 1 &&
      _vm.$isShowHighlights
    )?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'keyword-custom-selection':
        _vm.$isCustomSelection &&
        (_vm.filterPhrase(_vm.keyphrase, _vm.word)
          .map(function (p) { return _vm.isCustomSelected(_vm.findPhrase(_vm.keyphrase, p)); })
          .includes(true) ||
          _vm.isCustomSelected(_vm.word)),
      'keyword-double-multiply': true,
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_c('b',{staticClass:"font-weight-bold"},[_vm._v("Keyword")]),_vm._v(" spotted "+_vm._s((_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length)+" times at "),_vm._l((_vm.$storeAnalysis.combinedKeywords[_vm.word]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(keyword.begin === _vm.begin
          ? "font-weight: bold; text-decoration: none; pointer-events: none;"
          : '')},[_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]),_vm._v(_vm._s(" "))])}),_c('br'),_c('span',{staticStyle:{"color":"white"}},[_c('b',{staticClass:"font-weight-bold"},[_vm._v("Key phrase")]),_vm._v(" spotted "+_vm._s(_vm.phraseCount(_vm.keyphrase, _vm.word))+" times at ")]),_vm._l((_vm.filterPhrase(_vm.keyphrase, _vm.word)),function(phrase){return _c('div',{key:("phrase-" + phrase + "-" + _vm.wordIdx + "-" + _vm.begin),staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(phrase)+" - "),_vm._l((_vm.$storeAnalysis.combinedKeywords[phrase]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(_vm.isIncludedInPhrase(keyword)
            ? "font-weight: bold; text-decoration: none; pointer-events: none;"
            : '')},[(keyword.keyphrase[0].startsWith(keyword.word))?_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]):_vm._e(),_vm._v(_vm._s(" "))])}),_c('br')],2)})],2):(
      (_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length &&
      _vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length &&
      _vm.$isShowHighlights
    )?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'start-phrase': _vm.findPhrase(_vm.keyphrase, _vm.word).startsWith(_vm.word),
      'end-phrase': _vm.findPhrase(_vm.keyphrase, _vm.word).endsWith(_vm.word),
      'keyword-custom-selection':
        _vm.$isCustomSelection &&
        (_vm.isCustomSelected(_vm.findPhrase(_vm.keyphrase, _vm.word)) ||
          _vm.isCustomSelected(_vm.word)),
      'keyword-multiply': true,
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_c('b',{staticClass:"font-weight-bold"},[_vm._v("Keyword")]),_vm._v(" spotted "+_vm._s((_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length)+" times at "),_vm._l((_vm.$storeAnalysis.combinedKeywords[_vm.word]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(keyword.begin === _vm.begin
          ? "font-weight: bold; text-decoration: none; pointer-events: none;"
          : '')},[_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]),_vm._v(_vm._s(" "))])}),_c('br'),_c('span',{staticStyle:{"color":"white"}},[_c('b',{staticClass:"font-weight-bold"},[_vm._v("Key phrase")]),_vm._v(" spotted "+_vm._s(_vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length)+" times at ")]),_vm._l((_vm.findKeyPhraseArr(_vm.keyphrase, _vm.word)),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx + "-" + (keyword.word) + "-" + (keyword.begin)),staticClass:"keyword-time-container",style:(_vm.isIncludedInPhrase(keyword)
          ? "font-weight: bold; text-decoration: none; pointer-events: none;"
          : '')},[(keyword.keyphrase[0].startsWith(keyword.word))?_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]):_vm._e(),_vm._v(_vm._s(" "))])})],2):(
      _vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length &&
      _vm.filterPhrase(_vm.keyphrase, _vm.word).length > 1 &&
      _vm.$isShowHighlights
    )?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'keyword-custom-selection':
        _vm.$isCustomSelection &&
        _vm.filterPhrase(_vm.keyphrase, _vm.word)
          .map(function (p) { return _vm.isCustomSelected(_vm.findPhrase(_vm.keyphrase, p)); })
          .includes(true),
      'keyword-multiply': true,
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_vm._v(" Spotted "+_vm._s(_vm.phraseCount(_vm.keyphrase, _vm.word))+" times at phrases: "),_vm._l((_vm.filterPhrase(_vm.keyphrase, _vm.word)),function(phrase){return _c('div',{key:("phrase-" + phrase + "-" + _vm.wordIdx + "-" + _vm.begin),staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(phrase)+" - "),_vm._l((_vm.$storeAnalysis.combinedKeywords[phrase]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(_vm.isIncludedInPhrase(keyword)
            ? "font-weight: bold; text-decoration: none; pointer-events: none;"
            : '')},[(keyword.keyphrase[0].startsWith(keyword.word))?_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]):_vm._e(),_vm._v(_vm._s(" "))])}),_c('br')],2)})],2):(
      (_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length &&
      _vm.$isShowHighlights
    )?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'keyword-custom-selection':
        _vm.$isCustomSelection &&
        _vm.$storeAnalysis.keywordsCustomSelection.includes(_vm.word),
      'highlight-non-border keyword-highlight': true,
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_vm._v(" Spotted "+_vm._s((_vm.$storeAnalysis.combinedKeywords[_vm.word] || []).length)+" times "),_c('div',[_vm._v(" at "),_vm._l((_vm.$storeAnalysis.combinedKeywords[_vm.word]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(keyword.begin === _vm.begin
            ? "font-weight: bold; text-decoration: none; pointer-events: none;"
            : '')},[_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]),_vm._v(_vm._s(" "))])})],2)]):(
      (_vm.$storeAnalysis.combinedKeywords[_vm.keyword] || []).length &&
      _vm.keyword !== _vm.word &&
      _vm.$isShowHighlights
    )?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'keyword-custom-selection':
        _vm.$isCustomSelection &&
        _vm.$storeAnalysis.keywordsCustomSelection.includes(_vm.word),
      'highlight-non-border keyword-highlight': true,
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_vm._v(" Spotted "+_vm._s((_vm.$storeAnalysis.combinedKeywords[_vm.keyword] || []).length)+" times "),_c('div',[_vm._v(" at "),_vm._l((_vm.$storeAnalysis.combinedKeywords[_vm.keyword]),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(keyword.begin === _vm.begin
            ? "font-weight: bold; text-decoration: none; pointer-events: none;"
            : '')},[_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]),_vm._v(_vm._s(" "))])})],2)]):(_vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length && _vm.$isShowHighlights)?_c('AnalysisHighlightedWord',{attrs:{"btnClassNames":{
      'start-phrase': _vm.findPhrase(_vm.keyphrase, _vm.word).startsWith(_vm.word),
      'end-phrase': _vm.findPhrase(_vm.keyphrase, _vm.word).endsWith(_vm.word),
      'keyword-custom-selection':
        _vm.$isCustomSelection && _vm.isCustomSelected(_vm.findPhrase(_vm.keyphrase, _vm.word)),
    },"word":_vm.word,"begin":_vm.begin,"visibleWord":_vm.visibleWord}},[_vm._v(" Spotted "+_vm._s(_vm.findKeyPhraseArr(_vm.keyphrase, _vm.word).length)+" times "),_c('div',[_vm._v(" at "),_vm._l((_vm.findKeyPhraseArr(_vm.keyphrase, _vm.word)),function(keyword,keyIdx){return _c('span',{key:("keyword-" + _vm.idx + "-" + _vm.wordIdx + "-" + keyIdx),staticClass:"keyword-time-container",style:(_vm.isIncludedInPhrase(keyword)
            ? "font-weight: bold; text-decoration: none; pointer-events: none;"
            : '')},[(keyword.keyphrase[0].startsWith(keyword.word))?_c('span',{staticClass:"keyword-time",attrs:{"id":("keyword-" + (keyword.word) + "-" + (keyword.begin))},on:{"click":function($event){return _vm.trigger(("keyword-" + (keyword.word) + "-" + (keyword.begin)))}}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(keyword.begin)))]):_vm._e(),_vm._v(_vm._s(" "))])})],2)]):[_c('v-tooltip',{attrs:{"top":"","nudge-top":"-11"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"word"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getVisibleWord)+" ")]),_vm._v(" "+_vm._s(_vm.getHyphen)+" ")]}}])},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.calculateSpeechStartTime(_vm.begin)))])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }