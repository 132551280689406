import Vue from "vue";
import AnalysisResult from "./AnalysisResult/index.vue";
import AnalysisProgress from "./AnalysisProgress/index.vue";
import AnalysisAbortAlert from "./AnalysisAbortAlert/index.vue";
import SummaryInfo from "./SummaryInfo/index.vue";
import SpeakersList from "./SpeakersList/index.vue";
import AnalysisKeywords from "./AnalysisKeywords/index.vue";
import RenameModal from "@/components/modals/RenameModal/index.vue";
import Footer from "@/components/Footer/index.vue";
import Player from "@/components/Analysis/Player/index.vue";
import Loader from "@/components/Loader/index.vue";
import { Analysis, SpeakersListItem } from "@/store/analyzes/analyzes-types";
import { ITaskStatuses } from "@/types/shared-types";
import { ErrorsMessages } from "@/utils/errors";

export default Vue.extend({
  name: "AnalysisMain",
  components: {
    RenameModal,
    Footer,
    Player,
    AnalysisProgress,
    Loader,
    AnalysisResult,
    AnalysisAbortAlert,
    SummaryInfo,
    SpeakersList,
    AnalysisKeywords,
  },
  props: {
    loadingTask: {
      type: Boolean,
      default: false,
      required: true,
    },
    progressStatus: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: true,
      speakers: 0,
      speakersList: <Array<SpeakersListItem>>[],
      errorsMessages: ErrorsMessages,
      audioTime: 0,
    };
  },
  computed: {
    $currentStatus(): ITaskStatuses {
      return this.$storeAnalysis.status;
    },
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $APIError(): number | null {
      return this.$store.state.errorAPI;
    },
    $WSError(): number | null {
      return this.$store.state.errorWS;
    },
    keywordsCounter(): number {
      const keywords = this.$storeAnalysis.keywords_expected;
      if (keywords === "") return 0;
      const _keys = keywords.trim().split(",");
      if (_keys[_keys.length - 1] === "") return _keys.length - 1;
      return _keys.length;
    },
    showLoader(): boolean {
      return (
        !["failed", "aborted", "completed"].includes(
          this.$storeAnalysis.status
        ) || this.loadingTask
      );
    },
    showAbortAlert(): boolean {
      return this.$storeAnalysis.status === "aborted";
    },
    showError(): boolean {
      return (
        this.$storeAnalysis.status === "failed" ||
        (!!this.$APIError && [2181878257, 400].includes(this.$APIError)) ||
        (!!this.$WSError &&
          [2181878257, 1830715152, 400].includes(this.$WSError))
      );
    },
    coefficient(): number {
      return this.$store.getters["analyzes/GET_COEFFICIENT"];
    },
  },
  methods: {
    clearWSError(code: number | number[] | null) {
      if (this.$WSError) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    clearAPIError(code: number | number[] | null) {
      if (this.$APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    analyzeAgain() {
      this.$emit("analyzeAgain");
    },
    runLoader() {
      this.isLoaded = false;
      setTimeout(() => {
        this.isLoaded = true;
      }, 500);
    },
  },
});
