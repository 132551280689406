import Vue from "vue";
import AnalysisResultWord from "./AnalysisResultWord/index.vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { PERSON_COLORS } from "@/utils/helper";

export default Vue.extend({
  name: "AnalysisResultBlock",
  props: ["speak", "idx", "isBlockPlayed"],
  components: {
    AnalysisResultWord,
  },
  data() {
    return {
      personColors: <string[]>PERSON_COLORS,
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
  },
  methods: {
    getSpeakerName(speaker: number): string {
      return this.$storeAnalysis.speakers_names[speaker] || `Person ${speaker}`;
    },
  },
});
