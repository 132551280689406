var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.isBlockPlayed
      ? 'background-color: var(--person-highlight); border-radius: 4px'
      : '')},[(!_vm.speak.words[0])?_c('span'):_c('span',[_c('div',{staticClass:"result-speech",staticStyle:{"cursor":"default"}},[_c('b',{staticClass:"person-tag",style:(("background-color: " + (_vm.personColors[_vm.speak.speaker - 1])))},[_vm._v(_vm._s(_vm.getSpeakerName(_vm.speak.speaker)))]),_vm._l((_vm.speak.words),function(ref,wordIdx){
      var word = ref.word;
      var begin = ref.begin;
      var visibleWord = ref.visibleWord;
      var keyphrase = ref.keyphrase;
      var keyword = ref.keyword;
return _c('AnalysisResultWord',{key:("speech-" + _vm.idx + "-" + wordIdx),attrs:{"idx":_vm.idx,"word":word,"begin":begin,"visibleWord":visibleWord,"keyphrase":keyphrase,"keyword":keyword,"wordIdx":wordIdx}})})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }