import vuetify from "@/plugins/vuetify";

export function themeInterval(): void {
  const currentTime = new Date().getHours();
  if (currentTime > 5 && currentTime < 19) {
    const nextTime = new Date().setHours(19, 0, 0, 0);
    const msDif = nextTime - Date.now();
    setTimeout(() => {
      vuetify.framework.theme.dark = true;
      document.documentElement.className = "dark-theme";
    }, msDif);
  } else {
    let nextTime = 0;
    if (currentTime >= 19) {
      nextTime = new Date(new Date().setHours(6, 0, 0, 0)).setDate(
        new Date().getDate() + 1
      );
    }
    if (currentTime <= 5) {
      nextTime = new Date().setHours(6, 0, 0, 0);
    }
    const msDif = nextTime - Date.now();
    setTimeout(() => {
      vuetify.framework.theme.dark = false;
      document.documentElement.className = "light-theme";
    }, msDif);
  }
}
