import Vue from "vue";
import SizingTool from "./SizingTool/index.vue";
import Plans from "./Plans/index.vue";
import FAQBlock from "./FAQBlock/index.vue";
import { SuccessMessages } from "@/utils/success-messages";
import { IUser } from "@/store/profile/profile-types";
import { ErrorsMessages } from "@/utils/errors";
import router from "@/router";
export default Vue.extend({
  name: "Tariffication",
  components: { SizingTool, Plans, FAQBlock },
  data() {
    return {
      replySignUpDisabled: false,
      successMessages: SuccessMessages,
      errorsMessages: ErrorsMessages,
    };
  },
  computed: {
    currentUser(): IUser {
      return this.$store.state.profile.user;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    APIError(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  methods: {
    async replyConfirmSignUp() {
      if (this.APIError === 2829294403) return;

      const res = await this.$store.dispatch("profile/REPLY_CONFIRM_EMAIL");

      if (res) {
        this.$store.dispatch("SET_SUCCESS_ALERT", 4);
      } else {
        setTimeout(() => {
          this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
        }, 5000);
      }
    },
  },
  async created() {
    this.$store.dispatch("profile/GET_PRICES");
  },
  beforeDestroy() {
    if (this.APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
  },
});
