import { Tonality } from "@/store/analyzes/analyzes-types";
import Vue from "vue";

export default Vue.extend({
  name: "TonalityMetrics",
  props: ["person", "tonalities", "title"],
  methods: {
    getTotalPersonTonality(idxPerson: number, tonalitArr: any) {
      let sum = 0;
      tonalitArr.forEach((el: [string, number]) => {
        sum += el[1] as number;
      });
      return sum;
    },
    getPersonTonality(idx: number, tonalityArr: any) {
      const tonality: Array<Tonality> = tonalityArr.map(
        (el: [string, number]) => {
          return { name: el[0] as string, value: el[1] as number };
        }
      );
      let other = 100;
      const res = [];
      for (let i = 0; i < 3; i++) {
        const value = Math.floor(
          (tonality[i].value / this.getTotalPersonTonality(idx, tonalityArr)) *
            100
        );
        const resValue = tonality[i].value > 0.005 && value === 0 ? 1 : value;
        other -= resValue;
        if (resValue > 0) res.push({ name: tonality[i].name, value: resValue });
      }
      if (other > 0) res.push({ name: "Other", value: other >= 0 ? other : 0 });

      return res;
    },
  },
});
