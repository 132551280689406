import store from "@/store";

let queue: Array<string> = [];

export const addDownloadLink = (str: string): void => {
  queue.push(str);
  if (queue.length === 1) startQueue();
};

const startQueue = async () => {
  let counter = 0;
  do {
    await new Promise<void>((resolve) => {
      store.dispatch("analyzes/DOWNLOAD_PDF", queue[counter]);
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    counter++;
  } while (counter < queue.length);
  queue = [];
  counter = 0;
};
