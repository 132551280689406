import Vue from "vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import { VLIDATORS } from "@/utils/validators";
import { keywordsToArray, msOrSecFormat } from "@/utils/helper";

export default Vue.extend({
  name: "AnalysisKeywords",
  props: ["showLoader", "keywordsCounter", "showAbortAlert"],
  data() {
    return {
      rules: VLIDATORS,
      chooseCustomSelection: false,
      dangerHint: false,
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $storeKeywords(): Analysis["keywords_expected"] {
      return this.$storeAnalysis.keywords_expected;
    },
    $wordsRefs(): Record<string, HTMLElement> {
      return this.$storeAnalysis.wordsRefs;
    },
  },
  methods: {
    trigger(ref: string) {
      this.$wordsRefs[ref].click();
    },
    reCheckKeywords() {
      const isValid = (
        this.$refs.keywordsInput as Vue & { validate: () => boolean }
      ).validate();

      if (!isValid) return;

      this.$store.dispatch("analyzes/RECHECK_KEYWORDS");
      /*this.$emit("setLoad", false);
      this.$store.dispatch("analyzes/CALCULATE_STATISTICS_ACTION").then(() => {
        setTimeout(() => {
          this.$emit("setLoad", true);
        }, 500);
      });*/
    },
    keywordsInputHandler(event: KeyboardEvent) {
      if (this.keywordsCounter === 100 && event.key === ",") {
        event.preventDefault();
        return;
      }
    },
    normalizeKeywords() {
      this.$store.state.analyzes.currentAnalyze.keywords_expected =
        keywordsToArray(
          this.$store.state.analyzes.currentAnalyze.keywords_expected
        ).join(", ");
    },
    isCustomSelected(keyword: string): boolean {
      return this.$storeAnalysis.keywordsCustomSelection.includes(keyword);
    },
    customSelection(keyword: string) {
      this.dangerHint = false;
      this.$store.dispatch("analyzes/EDIT_CUSTOM_SELECTION_ACTION", keyword);
    },
    calculateSpeechStartTime(start: number) {
      return msOrSecFormat(start);
    },
    activateCustomSelection() {
      if (
        this.$store.state.analyzes.currentAnalyze.keywordsCustomSelection.length
      ) {
        this.chooseCustomSelection = false;
        this.$store.dispatch("analyzes/TOGGLE_CUSTOM_SELECTION_ACTION", true);
      }
      this.dangerHint = true;
    },
    cancelCustomSelection() {
      this.$store.dispatch("analyzes/CLEAR_CUSTOM_SELECTION_ACTION");
      this.chooseCustomSelection = false;
    },
  },
});
