import { IUser } from "@/store/profile/profile-types";
import { ErrorsMessages } from "@/utils/errors";
import { PASSWORD_VALIDATORS, VLIDATORS } from "@/utils/validators";
import Vue from "vue";

export default Vue.extend({
  name: "RenameModal",
  props: ["dialog"],
  data() {
    return {
      confirmSuccess: <boolean | null>null,
      loading: false,
      form: {
        password: "",
      },

      rules: VLIDATORS,
      passwordValidators: PASSWORD_VALIDATORS,
      showPass: false,
      errorsMessages: ErrorsMessages,
    };
  },
  methods: {
    clearError(code: number | number[] | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    async deleteAccount() {
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.loading = true;
        const res = await this.$store.dispatch(
          "profile/DELETE_ACCOUNT",
          this.form
        );
        this.loading = false;
        if (res) {
          const id = this.$store.state.profile.user.id;
          this.$store.dispatch("profile/LOGOUT_USER");
          this.$router.push({ name: "AccountDeleted", query: { id } });
        } else console.log("Failed");
      }
    },
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
    currentUser(): IUser {
      return this.$store.state.profile.user;
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
